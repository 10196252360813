export default function videoListNormalizeData( responseData ) {
  const nodes = [];
  const drupalData = responseData?.data;
  const drupalIncluded = responseData?.included;

  // change included array into object with id as property name
  // so we can retrieve them by their id later
  const drupalIncludedById = {};
  if ( drupalIncluded ) {
    drupalIncluded.forEach( ( item ) => {
      const { id } = item;
      drupalIncludedById[id] = item;
    } );
  }

  if ( drupalData?.length ) {
    drupalData.forEach( ( item, index ) => {
      const node = {
        uuid: item.id,
        videoId: item.attributes.field_video_id,
        service: item.attributes.field_video_service,
        title: item.attributes.title,
      };

      const coverImageFieldData = drupalData[index].relationships.field_cover_photo.data;
      if ( coverImageFieldData ) {
        node.coverImage = coverImageFieldData.meta.imageDerivatives.links.one_third_screen.href;
      }
      nodes.push( node );
    } );
  }

  return nodes;
}
