import React from 'react';
import propTypes from 'prop-types';

import MediaGalleryPageVirtualTourItem from './MediaPageVirtualTourListItem';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const MediaGalleryPageVirtualTourList = ( { items } ) => (
  <>
    { ( !items || !items.length )
      && <LoadingSpinner />}

    { items?.map( ( item ) => (
      <MediaGalleryPageVirtualTourItem
        key={item.uuid}
        item={item}
      />
    ) )}
  </>
);

MediaGalleryPageVirtualTourList.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape( {
      uuid: propTypes.string,
      title: propTypes.string,
      coverImage: propTypes.string,
    } ),
  ),
};

MediaGalleryPageVirtualTourList.defaultProps = {
  items: [],
};

export default MediaGalleryPageVirtualTourList;
