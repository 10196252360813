import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getNormalizedApiData } from '../../utils/ApiHelpers';
import placeListNormalizeData from '../Place/PlaceListNormalizeData';

import AmenitiesPageList from './AmenitiesPageList';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import '../../GlobalScss/grid.scss';

const AmenitiesPage = () => {
  const [ items, setItems ] = useState( [] );

  const { config } = useContext( ConfigContext );
  const { communityNid } = config;

  // Get normalized place data.
  useEffect( () => {
    const fetchData = async () => {
      const request = {
        entityType: 'node',
        contentType: 'place',
        params: {
          sort: 'field_display_order,title',
          include: 'field_cover_photo',
        },
        filter: {
          field_display_on_amenities_page: 1,
        },
      };
      const data = await getNormalizedApiData( request, communityNid, placeListNormalizeData );
      setItems( data );
    };
    if ( communityNid ) {
      fetchData();
    }
  }, [ communityNid ] );

  return (
    <div className="content">

      { items?.length === 0 && (
        <LoadingSpinner />
      )}

      <AmenitiesPageList
        items={items}
      />

    </div>
  );
};

export default AmenitiesPage;
