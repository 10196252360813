import React from 'react';
import propTypes from 'prop-types';

import VideoListItem from './MediaPageVideoListItem';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const MediaPageVideoList = ( { items } ) => (
  <>
    { ( !items || !items.length )
        && <LoadingSpinner />}

    { items?.map( ( item ) => (
      <VideoListItem
        key={item.uuid}
        item={item}
      />
    ) )}
  </>
);

MediaPageVideoList.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape( {
      uuid: propTypes.string,
      title: propTypes.string,
      coverImage: propTypes.string,
      videoId: propTypes.string,
      service: propTypes.string,
    } ),
  ),
};

MediaPageVideoList.defaultProps = {
  items: [],
};

export default MediaPageVideoList;
