import React, { useContext, useEffect, useState } from 'react';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import virtualTourDetailNormalizeData from './VirtualTourDetailNormalizeData';

import OnImageDropdown from '../OnImageDropdown/OnImageDropdown';

import './VirtualTour.scss';

const VirtualTourDetail = ( {
  id,
  uuid,
  threeSixtiesData,
  className,
} ) => {
  // Check that we have everything we need to proceed.
  if ( (!threeSixtiesData || threeSixtiesData.length === 0 ) && !uuid ) return null;

  const [ threeSixties, setThreeSixties ] = useState( null );
  const [ options, setOptions ] = useState( null );
  const [ activeVtPath, setActiveVtPath ] = useState( null );
  const [ activeType, setActiveType ] = useState( null );

  const { config } = useContext( ConfigContext );

  // if this component is being loaded by a place, unit, or floor plan detail,
  // we already have the threesixties and dont need to get them from the api
  useEffect( () => {
    if ( threeSixtiesData ) {
      setThreeSixties( threeSixtiesData );
    }
  }, [ threeSixtiesData ] );

  useEffect( () => {
    if ( uuid ) {
      const fetchData = async () => {
        const include = [
          'field_three_sixties',
        ];
        const request = {
          entityType: 'node',
          contentType: 'virtual_tour',
          uuid,
          params: {
            include: include.join( ',' ),
          },
        };
        const data = await getNormalizedApiDataByUuid( request, virtualTourDetailNormalizeData );
        setThreeSixties( data );
      };
      fetchData();
    }
  }, [ uuid ] );

  // loop through three sixties to get btns and xml info
  useEffect( () => {
    if ( threeSixties ) {
      const rawOptions = [];

      threeSixties.forEach(
        ( nodeThreeSixty, index ) => {
          rawOptions[index] = {
            type: nodeThreeSixty.type,
            id: nodeThreeSixty.uuid,
            text: nodeThreeSixty.buttonText,
            isDefault: index === 0,
            path: nodeThreeSixty.url,
          };
        },
      );

      setOptions( rawOptions );
    }
  }, [ threeSixties ] );

  // set initial vt path
  useEffect( () => {
    if ( options ) {
      options.forEach(
        ( option ) => {
          if ( option.isDefault ) {
            setActiveVtPath( option.path );
            setActiveType( option.type );
          }
        },
      );
    }
  }, [ options ] );

  // handle option click
  const handleOptionOnClick = ( option ) => {
    setActiveVtPath( option.path );
    setActiveType( option.type );
  };

  return (
    <div
      id={id}
      className={`virtualTour virtualTour--${activeType} ${className}`}
    >

      { config && config.primaryColor && activeType !== 'TruPlace'
        && <LoadingSpinner />}

      { activeVtPath
        && (
        <iframe
          title="Virtual Tour"
          className={`virtualTour__iframe virtualTour__iframe--${activeType}`}
          src={activeVtPath}
        />
        )}

      { options && options.length > 1
        && (
        <OnImageDropdown
          className={`virtualTour__dropdown virtualTour__dropdown--${activeType}`}
          optionsLocation="left"
          closeDropdownOnClick
          options={options}
          handleOptionOnClick={( option ) => handleOptionOnClick( option )}
        />
        )}

    </div>
  );
};

export default VirtualTourDetail;
