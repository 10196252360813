import React, { useContext } from 'react';
import classnames from 'classnames';

import MapContext from '../../../MapPage/MapContext';

import POIIcon from './POIIcon';
import HidePOIIcon from './HidePOIIcon';

import '../MapControls.scss';

const MapControlsPOIToggle = () => {
  const { setPOIVisible, POIVisible } = useContext( MapContext );

  return (
    <div className="mapControlToggle">
      <button
        type="button"
        className={classnames( 'mapControlToggle-toggleOption', { 'mapControlToggle-toggleOption--show': !POIVisible } )}
        onClick={() => {
          setPOIVisible( true );
        }}
      >
        <POIIcon />
      </button>

      <button
        type="button"
        className={classnames( 'mapControlToggle-toggleOption', { 'mapControlToggle-toggleOption--show': POIVisible } )}
        onClick={() => {
          setPOIVisible( false );
        }}
      >
        <HidePOIIcon />
      </button>

    </div>
  );
};

export default MapControlsPOIToggle;
