import React, { useContext } from 'react';

import ReactPlayer from 'react-player';
import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import './VideoDetail.scss';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const VideoDetail = ( {
  videoId,
  service,
  autoplay,
} ) => {
  // Check that we have everything we need to proceed.
  if ( !videoId || !service ) return null;

  const { config } = useContext( ConfigContext );
  const primaryColor = config ? config.primaryColor : '000000';

  let url = null;
  switch ( service ) {
    case 'Wistia':
      url = `//fast.wistia.net/embed/iframe/${videoId}`;
      break;
    case 'YouTube':
      url = `https://www.youtube.com/watch?v=${videoId}`;
      break;
    case 'Vimeo':
      url = `https://player.vimeo.com/video/${videoId}`;
      break;
    default:
  }

  return (
    <div
      id="video-scroll"
      className="video"
    >
      <LoadingSpinner />
      <div className="video__placeholder">
        <ReactPlayer
          url={url}
          className="video__player"
          width="100%"
          height="100%"
          controls
          playing={autoplay}
          config={{
            youtube: {
              // https://developers.google.com/youtube/player_parameters
              playerVars: {
                enablejsapi: 1,
                modestbranding: 1,
                rel: 0,
                iv_load_policy: 3,
                autoplay,
                controls: 1,
                color: primaryColor,
              },
            },
            vimeo: {
              // https://developer.vimeo.com/player/sdk/embed
              playerOptions: {
                id: videoId,
                autoplay,
                color: primaryColor,
                // responsive: true,
                title: false,
                portrait: false,
                autopause: true,
                controls: true,
              },
            },
            wistia: {
              // https://wistia.com/support/developers/embed-options
              options: {
                playerColor: primaryColor,
                videoFoam: true,
                autoPlay: autoplay,
                controlsVisibleOnLoad: true,
                playBar: true,
              },
            },
          }}
        />
      </div>
    </div>
  );
};

export default VideoDetail;
