import React, {
  useContext, useEffect, useRef, useState,
} from 'react';

import MapContext from '../MapContext';

import { getCenter } from '../../../utils/common';
import { getOverlayBounds, getCampusBounds, getMaxZoomBounds } from './helpers';

import OverlayViewContainer from './OverlayViewContainer';
import MapOverlay from '../../Map/MapOverlay/MapOverlay';

import GoogleMapStyle from './GoogleMapStyle';
import GoogleMapWithPOIStyle from './GoogleMapWithPOIStyle';
import GoogleMapHybridStyle from './GoogleMapHybridStyle';
import GoogleMapHybridWithPOIStyle from './GoogleMapHybridWithPOIStyle';

import './GoogleMap.scss';

const GoogleMap = ( {
  secondaryColor,
  siteSeeGuideId,
  setSiteSeeGuideId,
  siteSeeGuideMode,
} ) => {
  const googleMapContainer = useRef();
  const [ map, setMap ] = useState( null );
  const [ commPinLatLng, setCommPinLatLng ] = useState( null );
  const [ activeCenterLatLng, setActiveCenterLatLng ] = useState( null );
  const [ commPin, setCommPin ] = useState( null );
  const [ commPinVisible, setCommPinVisible ] = useState( null );
  const [ commPinOptions, setCommPinOptions ] = useState( null );
  const [ overlayBounds, setOverlayBounds ] = useState( null );

  const {
    mapData,
    activeMapType,
    POIVisible,
    overlayVisible,
    setActiveZoom,
    activeZoom,
    setFitAndPan,
    fitAndPan,
    setMapInstance,
    isSiteSee,
    setFiltersVisible,
    setLevelsControlVisible,
    setMarkersToggleVisible,
    setOverlayVisible,
  } = useContext( MapContext );

  const {
    googleMapsConfig,
    tileSource,
    mapZoomToEnableLevels,
    mapZoomToEnableOverlay,
  } = mapData;

  const {
    campusCenter,
    address,
    maxZoomBounds,
    zooms,
    campusBounds,
    siteSee,
  } = googleMapsConfig || {};

  const {
    center: siteSeeCenter,
    initialZoom: siteSeeInitialZoom,
  } = siteSee || {};

  // Set overlay bounds.
  useEffect( () => {
    const rawOverlayBounds = tileSource ? getOverlayBounds( tileSource ) : null;
    setOverlayBounds( rawOverlayBounds );
  }, [ tileSource ] );

  // Get community pin latlng.
  useEffect( () => {
    const initializeCenter = async () => {
      const center = await getCenter(
        campusCenter,
        address,
      );
      return new Promise( ( resolve, reject ) => {
        resolve( center );
      } );
    };
    initializeCenter().then( ( center ) => {
      setCommPinLatLng( center );
    } );
  }, [ campusCenter, address ] );

  // Get map center based on if SiteSee or not.
  useEffect( () => {
    const activeCenter = ( isSiteSee && siteSeeCenter?.lat && siteSeeCenter?.lng )
      ? siteSeeCenter : commPinLatLng;
    setActiveCenterLatLng( activeCenter );
  }, [
    commPinLatLng,
    siteSeeCenter,
    isSiteSee,
  ] );

  // Load Google Maps API and create map
  useEffect( () => {
    if ( maxZoomBounds && zooms && activeCenterLatLng ) {
      const processedMaxZoomBounds = getMaxZoomBounds( maxZoomBounds );
      const nonSiteSeeInitialZoom = zooms.initial || mapZoomToEnableLevels;
      const initialZoom = isSiteSee ? siteSeeInitialZoom : nonSiteSeeInitialZoom;

      const mapOptions = {
        center: activeCenterLatLng,
        zoom: initialZoom,
        minZoom: zooms.min,
        maxZoom: zooms.max,
        disableDefaultUI: true,
        clickableIcons: false,
        styles: GoogleMapStyle,
      };

      if ( processedMaxZoomBounds ) {
        mapOptions.restriction = {
          latLngBounds: processedMaxZoomBounds,
          strictBounds: false,
        };
      }

      const createdMap = new window.google.maps.Map(
        googleMapContainer.current,
        mapOptions,
      );

      setMap( createdMap );
      setMapInstance( createdMap );
    }
  }, [
    maxZoomBounds,
    zooms,
    siteSeeInitialZoom,
    isSiteSee,
    activeCenterLatLng,
    mapZoomToEnableLevels,
  ] );

  // Toggle POI
  // This also toggles filters and levels controls.
  useEffect( () => {
    if ( map && activeMapType ) {
      let activeMapStyle = GoogleMapStyle;
      if ( activeMapType === 'hybrid' && POIVisible ) {
        setLevelsControlVisible( false );
        setFiltersVisible( false );
        activeMapStyle = GoogleMapHybridWithPOIStyle;
      } else if ( activeMapType === 'hybrid' ) {
        setLevelsControlVisible( false );
        setFiltersVisible( false );
        activeMapStyle = GoogleMapHybridStyle;
      } else if ( activeMapType === 'roadmap' && POIVisible ) {
        setLevelsControlVisible( false );
        setFiltersVisible( false );
        activeMapStyle = GoogleMapWithPOIStyle;
      } else if ( activeMapType === 'roadmap' ) {
        setLevelsControlVisible( true);
        setFiltersVisible( true );
      }
      map.setOptions( {
        styles: activeMapStyle,
      } );
    }
  }, [
    map,
    POIVisible,
    activeMapType,
  ] );

  useEffect( () => {
    if ( map ) {
      setFitAndPan( true );
      map.addListener( 'zoom_changed', () => {
        const newZoom = map.getZoom();
        setActiveZoom( newZoom );
      } );
    } else {
      setFitAndPan( false );
      // When Google maps can't find map, it automatically sets zoom to 10. This
      // is a workaround to set zoom to initial zoom.
      const nonSiteSeeInitialZoom = zooms.initial || mapZoomToEnableLevels;
      const siteSeeZoom = siteSeeInitialZoom || nonSiteSeeInitialZoom;
      const noMapZoom = isSiteSee ? siteSeeZoom : nonSiteSeeInitialZoom;
      setActiveZoom( noMapZoom );
    }
  }, [
    map,
    isSiteSee,
    zooms,
    mapZoomToEnableLevels,
    siteSeeInitialZoom,
  ] );

  // Toggle map type, i.e. satelitte, hybrid, roadmap.
  useEffect( () => {
    if ( map && activeMapType ) {
      map.setMapTypeId( activeMapType );
    }
  }, [ map, activeMapType ] );

  // Show/hide filters and markers based on zoom level.
  useEffect( () => {
    if ( map && mapZoomToEnableLevels && activeZoom ) {
      map.setZoom( activeZoom );
      if ( activeZoom >= mapZoomToEnableLevels ) {
        setFiltersVisible( true );
        setLevelsControlVisible( true );
        setMarkersToggleVisible( true );
      } else {
        setFiltersVisible( false );
        setLevelsControlVisible( false );
        setMarkersToggleVisible( false );
      }
    }
  }, [
    map,
    mapZoomToEnableLevels,
    activeZoom,
  ] );

  // Toggle between seeing the overlay or the comm pin.
  useEffect( () => {
    if ( mapZoomToEnableOverlay && activeZoom && activeMapType ) {
      if ( activeMapType === 'hybrid' ) {
        setOverlayVisible( false );
        setCommPinVisible( true );
      } else if ( activeZoom >= mapZoomToEnableOverlay ) {
        setOverlayVisible( true );
        setCommPinVisible( false );
        if ( POIVisible ) {
          setOverlayVisible( false );
          setCommPinVisible( true );
        }
      } else {
        setOverlayVisible( false );
        setCommPinVisible( true );
      }
    }
  }, [
    mapZoomToEnableOverlay,
    activeZoom,
    activeMapType,
    POIVisible,
    commPin,
  ] );

  // Fit and pan to campus bounds if not SiteSee, zoom and center to SiteSee
  // center and initial zoom if SiteSee.
  useEffect( () => {
    if ( map && fitAndPan ) {
      const processedCampusBounds = getCampusBounds(
        campusBounds,
        overlayBounds,
      );

      const nonSiteSeeInitialZoom = zooms.initial || mapZoomToEnableLevels;
      const siteSeeZoom = siteSeeInitialZoom || nonSiteSeeInitialZoom;

      if ( isSiteSee && ( ( siteSeeCenter?.lat && siteSeeCenter?.lng ) || siteSeeInitialZoom ) ) {
        map.setZoom( siteSeeZoom );
        map.panTo( activeCenterLatLng );
        setFitAndPan( false );
      } else if ( processedCampusBounds ) {
        map.fitBounds( processedCampusBounds );
        map.panToBounds( processedCampusBounds );
        // Don't set new zoom here. The zoom listener will take care of that.
        setFitAndPan( false );
      }
    }
  }, [
    map,
    fitAndPan,
    campusBounds,
    overlayBounds,
    commPinLatLng,
    mapZoomToEnableLevels,
    activeCenterLatLng,
    siteSeeInitialZoom,
    zooms,
  ] );

  // map comm pin options
  useEffect( () => {
    if ( map && commPinLatLng && activeMapType ) {
      const strokeColor = activeMapType === 'hybrid' ? 'black' : 'white';
      const commPinIcon = {
        path: `M32.9,0C14.7,0,0,14.7,0,32.9S26.4,80,32.9,80s32.9-28.9,32.9-47.1S51.1,0,32.9,0z M32.9,60.1c-15.2,0-27.5-12.3-27.5-27.5
        S17.7,5.1,32.9,5.1s27.5,12.3,27.5,27.5S48.1,60.1,32.9,60.1z M49.6,29.3L34.3,15.4c-0.8-0.7-2-0.7-2.8,0L16.2,29.3
        c-0.7,0.7-0.9,1.8-0.4,2.6c0.4,0.6,1.1,1,1.9,1h2v13.2c0,1.1,0.9,2.1,2.1,2.1h4.9c1.1,0,2.1-0.9,2.1-2.1v-7.6c0-1.1,0.9-2.1,2.1-2.1
        H35c1.1,0,2.1,0.9,2.1,2.1v7.6c0,1.1,0.9,2.1,2.1,2.1H44c1.1,0,2.1-0.9,2.1-2.1V32.9h2c0.8,0,1.5-0.4,1.9-1
        C50.5,31,50.3,29.9,49.6,29.3L49.6,29.3z`,
        fillColor: secondaryColor,
        fillOpacity: 1,
        strokeColor,
        size: new window.google.maps.Size( 68.5, 80 ),
        origin: new window.google.maps.Point( 0, 0 ),
        anchor: new window.google.maps.Point( 34.25, 80 ),
        zIndex: 1000,
      };

      const rawCommPinOptions = {
        position: commPinLatLng,
        icon: commPinIcon,
      };

      setCommPinOptions( rawCommPinOptions );
    }
  }, [
    map, commPinLatLng, secondaryColor, activeMapType,
  ] );

  // create comm pin object
  useEffect( () => {
    if ( map && commPinOptions ) {
      const commPinObject = new window.google.maps.Marker( commPinOptions );
      setCommPin( commPinObject );
    }
  }, [ map, commPinOptions ] );

  // add/remove comm pin
  // setVisible doesn't work consistently for some reason
  // so we can't use it
  useEffect( () => {
    if ( map && commPin ) {
      if ( commPinVisible ) {
        commPin.setMap( map );
      } else {
        commPin.setMap( null );
      }
    }
  }, [
    commPinVisible, commPin, map,
  ] );

  // change comm pin options stroke color according to activeMapType
  useEffect( () => {
    if ( activeMapType && commPinOptions ) {
      const strokeColor = activeMapType === 'hybrid' ? 'black' : 'white';
      commPinOptions.icon.strokeColor = strokeColor;
      setCommPinOptions( commPinOptions );
    }
  }, [ activeMapType, commPinOptions ] );

  // change comm pin stroke color according to activeMapType
  useEffect( () => {
    if ( commPin && activeMapType && commPinOptions ) {
      const strokeColor = activeMapType === 'hybrid' ? 'black' : 'white';
      commPinOptions.icon.strokeColor = strokeColor;
      commPin.setOptions( commPinOptions );
    }
  }, [
    activeMapType, commPin, commPinOptions,
  ] );

  return (
    <div className="mapShell" ref={googleMapContainer}>
      { overlayVisible && (
        <OverlayViewContainer
          map={map}
          overlayBounds={overlayBounds}
          content={(
            <MapOverlay
              siteSeeGuideId={siteSeeGuideId}
              setSiteSeeGuideId={setSiteSeeGuideId}
              siteSeeGuideMode={siteSeeGuideMode}
            />
          )}
        />
      )}
    </div>
  );
};

export default GoogleMap;
