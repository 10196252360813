/* eslint-disable import/prefer-default-export */
export function assembleResidenceNavBtnsData( unitData ) {
  const navBtns = [];

  if ( !unitData ) {
    return navBtns;
  }

  const {
    roomFees,
    description,
    twoDFloorPlan,
    threeDFloorPlan,
    roomDims,
    virtualTour,
    finishCollection,
    furnitureConfiguratorUrl,
    video,
    view,
    photoGallery,
  } = unitData;

  navBtns.push( {
    section: 'info-scroll',
    text: 'Info',
  } );

  if ( twoDFloorPlan || threeDFloorPlan ) {
    navBtns.push( {
      section: 'floorplan-scroll',
      text: 'Floor Plan',
    } );
  }

  if ( roomDims?.length > 0 ) {
    navBtns.push( {
      section: 'dimensions-scroll',
      text: 'Dimensions',
    } );
  }

  if ( view ) {
    navBtns.push( {
      section: 'view-scroll',
      text: 'View',
    } );
  }

  if ( photoGallery?.images.length > 0 ) {
    navBtns.push( {
      section: 'slideshow-scroll',
      text: 'Photos',
    } );
  }

  if ( video?.serviceVideoId && video?.service ) {
    navBtns.push( {
      section: 'video-scroll',
      text: 'Video',
    } );
  }

  if ( virtualTour?.threeSixties.length > 0 ) {
    navBtns.push( {
      section: 'tour-scroll',
      text: 'Tour',
    } );
  }

  if ( furnitureConfiguratorUrl ) {
    navBtns.push( {
      section: 'configurator-scroll',
      text: 'Furniture Layout',
    } );
  }

  if ( finishCollection && Object.keys( finishCollection ).length !== 0 ) {
    navBtns.push( {
      section: 'finishes-scroll',
      text: 'Finishes',
    } );
  }

  return navBtns;
}
