import React from 'react';
import PropTypes from 'prop-types';

import './DetailTable.scss';

const DetailTable = ( {
  id,
  tableInfo,
  tableDescription,
  className,
} ) => {
  if ( ( !tableInfo || tableInfo.length === 0 ) && ( !tableDescription || tableDescription === '' ) ) {
    return null;
  }

  return (
    <div id={id} className={`detailTable ${className}`}>

      { tableInfo && tableInfo.length > 0 && (
        <div className="detailTable-list">
          { tableInfo.map( ( stat ) => (
            <div className="detailTable-list__item" key={ stat.label }>
              <div>
                <div className="detailTable-list__item__label">
                  { stat.label }
                  :
                </div>
                <div className="detailTable-list__item__data">
                  { stat.data }
                </div>
              </div>
              { stat.note && (
                <div className="detailTable-list__item__note">
                  { stat.note }
                </div>
              )}
            </div>
          ) )}
        </div>
      )}

      { tableDescription && tableDescription !== '' && (
        <p className="detailTable-description">
          { tableDescription }
        </p>
      )}

    </div>
  );
};

DetailTable.propTypes = {
  id: PropTypes.string,
  tableInfo: PropTypes.arrayOf(
    PropTypes.shape( {
      label: PropTypes.string,
      data: PropTypes.string,
      note: PropTypes.string,
    } ),
  ),
  tableDescription: PropTypes.string,
  className: PropTypes.string,
};

DetailTable.defaultProps = {
  id: '',
  tableInfo: [],
  tableDescription: '',
  className: '',
};

export default DetailTable;
