import React, {
  useContext,
  useEffect,
} from 'react';
import propTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import DetailTableInfo from '../Detail/DetailTable';
import FloorPlanImagesWidget from './FloorPlanImagesWidget';
import ResidenceView from '../Residence/ResidenceView';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import VideoDetail from '../Video/VideoDetail';
import FurnitureConfigurator from '../FurnitureConfigurator/FurnitureConfigurator';
import FinishCollection from '../FinishCollection/FinishCollection';
import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';

import '../Detail/Detail.scss';

const FloorPlanDetailCore = ( {
  title,
  roomFees,
  floorplan2d,
  floorplan3d,
  downloadablePdf,
  roomData,
  view,
  photoGalleryImages,
  video,
  threeSixties,
  furnitureConfiguratorUrl,
  finishCollection,
} ) => {
  const {
    featureFlags,
    config,
    isKiosk,
    showPricing,
    setShowPricing,
    setUnitHasDisplayableFees,
  } = useContext( ConfigContext );
  const { isSiteSee, isPIB } = config;

  // Should we show pricing?
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_pricing' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_pricing' );
    } else if ( isSiteSee ) {
      show = featureFlags?.includes( 'sitesee_show_pricing' );
    } else {
      show = featureFlags?.includes( 'yt_show_pricing' );
    }
    setShowPricing( show );
  }, [
    isKiosk, isPIB, isSiteSee, featureFlags,
  ] );

  // If a floor plan has pricing data and can show that data, set context to show
  // that the floor plan has fees.
  const hasRoomFees = roomFees?.length > 0;
  useEffect( () => {
    if ( hasRoomFees > 0 && showPricing ) {
      setUnitHasDisplayableFees( true );
    } else {
      setUnitHasDisplayableFees( false );
    }
  }, [ showPricing,
    hasRoomFees ] );

  return (
    <>

      <FloorPlanImagesWidget
        className="detailSection"
        threeD={floorplan3d}
        twoD={floorplan2d}
        name={title}
        downloadablePdf={isPIB ? downloadablePdf : null}
      />

      <DetailTableInfo
        id="dimensions-scroll"
        className="detailSection"
        tableInfo={roomData}
      />

      <ResidenceView
        viewsData={view}
      />

      <PhotoGalleryDetail
        id="slideshow-scroll"
        uuid={null}
        images={photoGalleryImages}
        galleryClass="slideshow detailSection detailSection--gallery"
        imageClass="slideshowPhoto"
      />

      <VideoDetail
        hasId
        videoId={video?.videoId}
        service={video?.service}
        autoplay={false}
        className="detailSection"
      />

      <VirtualTourDetail
        id="tour-scroll"
        uuid={null}
        threeSixtiesData={threeSixties}
        className="detailSection modalSection--sixteen9"
      />

      <FurnitureConfigurator
        id="configurator-scroll"
        url={furnitureConfiguratorUrl}
        className="detailSection modalSection--sixteen9 modalSection--border"
      />

      <FinishCollection
        id="finishes-scroll"
        finishCollection={finishCollection}
        className="detailSection"
        isSiteSee={isSiteSee}
      />
    </>
  );
};

FloorPlanDetailCore.propTypes = {
  title: propTypes.string,
  roomFees: propTypes.arrayOf(
    propTypes.shape( {
      label: propTypes.string,
      data: propTypes.string,
      note: propTypes.string,
    } ),
  ),
  floorplan2d: propTypes.string,
  floorplan3d: propTypes.string,
  roomData: propTypes.arrayOf(
    propTypes.shape( {
      label: propTypes.string,
    } ),
  ),
  view: propTypes.arrayOf(
    propTypes.shape( {
      type: propTypes.string,
      data: propTypes.oneOfType(
        propTypes.shape( {
          service: propTypes.string,
          serviceVideoId: propTypes.string,
        } ),
        propTypes.arrayOf( propTypes.shape( {
          uuid: propTypes.string,
          image: propTypes.string,
          caption: propTypes.string,
        } ) ),
      ),
    } ),
  ),
  photoGalleryImages: propTypes.arrayOf(
    propTypes.shape( {
      id: propTypes.string,
      image: propTypes.shape( {
        modal: propTypes.string,
      } ),
      caption: propTypes.string,
    } ),
  ),
  threeSixties: propTypes.arrayOf(
    propTypes.shape( {
      id: propTypes.string,
      image: propTypes.string,
    } ),
  ),
  furnitureConfiguratorUrl: propTypes.string,
  video: propTypes.shape( {
    videoId: propTypes.string,
    service: propTypes.string,
  } ),
  finishCollection: propTypes.shape( {
    id: propTypes.string,
    title: propTypes.string,
    options: propTypes.arrayOf(
      propTypes.shape( {
        title: propTypes.string,
        fields: propTypes.arrayOf(
          propTypes.shape( {
            label: propTypes.string,
            data: propTypes.string,
          } ),
        ),
      } ),
    ),
  } ),
  downloadablePdf: propTypes.string,
};

FloorPlanDetailCore.defaultProps = {
  title: '',
  roomFees: [],
  floorplan2d: '',
  floorplan3d: '',
  roomData: [],
  view: [],
  photoGalleryImages: [],
  threeSixties: [],
  furnitureConfiguratorUrl: '',
  video: {},
  finishCollection: {},
  downloadablePdf: '',
};

export default FloorPlanDetailCore;
