import React, { useEffect, useState } from 'react';

import FloorPlanDetail from '../FloorPlan/FloorPlanDetail';
import PlaceDetail from '../Place/PlaceDetail';
import SubpaneMediaDetail from './SubpaneMediaDetail';

const Subpane = ( {
  subpaneData,
  subpaneComponentName,
} ) => {
  const [ subpaneComponent, setSubpaneComponent ] = useState( null );

  useEffect( () => {
    if ( subpaneData && subpaneComponentName ) {
      // eslint-disable-next-line react/jsx-no-useless-fragment
      let rawSubpaneComponent = <></>;
      switch ( subpaneComponentName ) {
        case 'FloorPlanDetail':
          rawSubpaneComponent = (
            <FloorPlanDetail
              uuid={null}
              data={subpaneData}
              setNavBtns={null}
              hasInternalHeader
              navBtns={null}
              navClass="detailNav--centered pobPage-favorites__detailNav"
            />
          );
          break;
        case 'PlaceDetail':
          rawSubpaneComponent = (
            <PlaceDetail
              uuid={null}
              data={subpaneData}
              setModalNavBtns={null}
              hasInternalHeader
              navBtns={null}
              navClass="detailNav--centered pobPage-favorites__detailNav"
            />
          );
          break;
        case 'MediaDetail':
          rawSubpaneComponent = (
            <SubpaneMediaDetail
              data={subpaneData}
              navClass="detailNav--centered pobPage-favorites__detailNav"
            />
          );
          break;
        default:
      }
      setSubpaneComponent( rawSubpaneComponent );
    }
  }, [ subpaneData, subpaneComponentName ] );

  return (
    <div className="subpane">
      { subpaneComponent }
    </div>
  );
};

export default Subpane;
