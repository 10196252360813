import React from 'react';
import propTypes from 'prop-types';

import PhotoGalleryDetail from '../PhotoGallery/PhotoGalleryDetail/PhotoGalleryDetail';
import VideoDetail from '../Video/VideoDetail';

const ResidenceView = ( { viewsData } ) => {
  if ( !viewsData || viewsData.length === 0 ) {
    return null;
  }

  return (
    <div id="view-scroll">

      { viewsData.map( ( item ) => {
        const { type, data } = item;
        let component = null;
        switch ( type ) {
          case 'PhotoGallery':
            component = (
              <PhotoGalleryDetail
                key={data[0]?.id}
                id={null}
                uuid={null}
                images={data}
                galleryClass="slideshow"
                imageClass="slideshowPhoto"
              />
            );
            break;
          case 'Video':
            component = (
              <VideoDetail
                key={data?.serviceVideoId}
                hasId={false}
                videoId={data?.serviceVideoId}
                service={data?.service}
                autoplay={false}
              />
            );
            break;
          default:
        }
        return component;
      } )}

    </div>
  );
};

ResidenceView.propTypes = {
  viewsData: propTypes.arrayOf(
    propTypes.shape( {
      type: propTypes.string,
      data: propTypes.oneOfType(
        propTypes.shape( {
          service: propTypes.string,
          serviceVideoId: propTypes.string,
        } ),
        propTypes.arrayOf( propTypes.shape( {
          uuid: propTypes.string,
          image: propTypes.string,
          caption: propTypes.string,
        } ) ),
      ),
    } ),
  ),
};

ResidenceView.defaultProps = {
  viewsData: [],
};

export default ResidenceView;
