import React, { useCallback, useContext, useState } from 'react';
import propTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import ModalLite from '../Modal/ModalLite';
import VirtualTourDetail from '../VirtualTour/VirtualTourDetail';
import Favorite from '../Personalization/Favorite/Favorite';

const MediaGalleryPageVirtualTourListItem = ( { item } ) => {
  const [ modalIsOpen, setModalIsOpen ] = useState( false );

  const { personalizationActive } = useContext( ConfigContext );

  const {
    uuid,
    title,
    coverImage,
  } = item;

  const openModal = useCallback( () => {
    setModalIsOpen( true );
  }, [] );

  return (
    <>
      <ModalLite
        isOpen={modalIsOpen}
        handleModalHide={() => setModalIsOpen( false )}
        setModalIsOpen={setModalIsOpen}
        showBlur
        modalLiteHeaderData={{
          title: {
            title,
          },
          navBtns: [],
        }}
      >

        <VirtualTourDetail
          id={null}
          uuid={uuid}
          threeSixtiesData={null}
          className="detailSection"
        />

      </ModalLite>

      <div className="gridItem">
        <button
          className="modalOpen gridItemTrigger"
          onClick={openModal}
          type="button"
        >
          <div>
            <div className="imgContainer">
              <div className="imgHolder" style={{ backgroundImage: `url(${coverImage})` }} />
              <h2 className="gridItemTitle type-display-xl">{title}</h2>
            </div>
          </div>
        </button>

        { personalizationActive && (
          <Favorite
            type="virtual_tour"
            uuid={uuid}
            hasShadow={false}
          />
        )}

      </div>
    </>
  );
};

MediaGalleryPageVirtualTourListItem.propTypes = {
  item: propTypes.shape( {
    uuid: propTypes.string,
    title: propTypes.string,
    coverImage: propTypes.string,
  } ).isRequired,
};

export default MediaGalleryPageVirtualTourListItem;
