import React, {
  useCallback, useContext, useState,
} from 'react';
import propTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';

import ModalLite from '../Modal/ModalLite';
import PlaceDetail from '../Place/PlaceDetail';
import Favorite from '../Personalization/Favorite/Favorite';

const AmenitiesPageListItem = ( { item } ) => {
  const [ modalIsOpen, setModalIsOpen ] = useState( false );
  const [ modalNavBtns, setModalNavBtns ] = useState( [] );

  const { personalizationActive } = useContext( ConfigContext );

  const {
    uuid,
    title,
    coverImage,
  } = item;

  const openModal = useCallback( () => {
    setModalIsOpen( true );
  }, [] );

  return (
    <>
      <ModalLite
        isOpen={modalIsOpen}
        handleModalHide={() => setModalIsOpen( false )}
        setModalIsOpen={setModalIsOpen}
        showBlur
        modalLiteHeaderData={{
          title: {
            title,
          },
          navBtns: modalNavBtns,
        }}
      >

        <PlaceDetail
          uuid={uuid}
          data={item}
          setModalNavBtns={setModalNavBtns}
          hasInternalHeader={false}
          navBtns={null}
          navClass=""
        />

      </ModalLite>

      <div className="gridItem">
        <button
          className="modalOpen gridItemTrigger"
          onClick={openModal}
          type="button"
        >
          <div>
            <div className="imgContainer">
              <div className="imgHolder" style={{backgroundImage: `url(${coverImage})`}}/>
              <h2 className="gridItemTitle type-display-xl">{title}</h2>
            </div>
          </div>
        </button>

        { personalizationActive && (
          <Favorite
            type="place"
            uuid={uuid}
            hasShadow
          />
        )}

      </div>
    </>
  );
};

AmenitiesPageListItem.propTypes = {
  item: propTypes.shape( {
    uuid: propTypes.string,
    title: propTypes.string,
    coverImage: propTypes.string,
  } ).isRequired,
};

export default AmenitiesPageListItem;
