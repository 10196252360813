import React, { useContext, useEffect, useState } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getNormalizedApiData } from '../../utils/ApiHelpers';
import photoGalleryListNormalizeData from '../PhotoGallery/PhotoGalleryListNormalizeData';
import virtualTourListNormalizeData from '../VirtualTour/VirtualTourListNormalizeData';
import videoListNormalizeData from '../Video/VideoListNormalizeData';

import HorizontalNavBarItem from '../Page/HorizontalNavBar/HorizontalNavBarItem';
import FadingContainer from '../FadingContainer/FadingContainer';
import MediaPagePhotoGalleryList from './MediaPagePhotoGalleryList';
import MediaPageVirtualTourList from './MediaPageVirtualTourList';
import MediaPageVideoList from './MediaPageVideoList';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

import './MediaPage.scss';
import '../../GlobalScss/grid.scss';

const MediaPage = ( { location, history } ) => {
  const [ photoGalleryData, setPhotoGalleryData ] = useState( null );
  const [ videoData, setVideoData ] = useState( null );
  const [ virtualTourData, setVirtualTourData ] = useState( null );
  const [ navMarkup, setNavMarkup ] = useState( false );

  const { config } = useContext( ConfigContext );
  const { communityNid } = config;

  // Maintain url params.
  const { search } = useLocation();

  // Fetch photo gallery, virtual tour, and video API data.
  useEffect( () => {
    const fetchData = async () => {
      const baseRequest = {
        entityType: 'node',
        params: {
          include: 'field_cover_photo',
          sort: 'field_display_order,title',
        },
        filter: {
          field_display_on_gallery_page: 1,
        },
      };

      const photoGalleryRequest = baseRequest;
      photoGalleryRequest.contentType = 'photo_gallery';
      const rawPhotoGalleryData = await getNormalizedApiData(
        photoGalleryRequest,
        communityNid,
        photoGalleryListNormalizeData,
      );
      setPhotoGalleryData( rawPhotoGalleryData );

      const virtualTourRequest = baseRequest;
      virtualTourRequest.contentType = 'virtual_tour';
      const rawVirtualTourData = await getNormalizedApiData(
        virtualTourRequest,
        communityNid,
        virtualTourListNormalizeData,
      );
      setVirtualTourData( rawVirtualTourData );

      const videoRequest = baseRequest;
      videoRequest.contentType = 'video';
      const rawVideoData = await getNormalizedApiData(
        videoRequest,
        communityNid,
        videoListNormalizeData,
      );
      setVideoData( rawVideoData );
    };
    fetchData();
  }, [ communityNid ] );

  useEffect( () => {
    if ( photoGalleryData && virtualTourData && videoData) {
      const gotPhotos = photoGalleryData?.length > 0;
      const gotTours = virtualTourData?.length > 0;
      const gotVideos = videoData?.length > 0;

      // Build the nav if we've got multiple types of content.
      const contentLengths = [
        photoGalleryData?.length,
        virtualTourData?.length,
        videoData?.length,
      ];
      const filteredContentLengths = contentLengths.filter(
        ( length ) => length > 0,
      );
      if ( filteredContentLengths?.length > 1 ) {
        const nav = (
          <div className="filterBtn">
            {gotPhotos && (
              <HorizontalNavBarItem path="/media/galleries" label="Photos" />
            )}
            {gotTours && (
              <HorizontalNavBarItem path="/media/tours" label="Tours" />
            )}
            {gotVideos && (
              <HorizontalNavBarItem path="/media/videos" label="Videos" />
            )}
          </div>
        );
        setNavMarkup( nav );
      } else {
        setNavMarkup( false );
      }

      // Show content for the first available section.
      switch ( true ) {
        case gotPhotos:
          history.push( `/media/galleries/${search}` );
          break;
        case gotTours:
          history.push( `/media/tours/${search}` );
          break;
        case gotVideos:
          history.push( `/media/videos/${search}` );
          break;
        default:
          break;
      }
    }
  }, [
    photoGalleryData,
    virtualTourData,
    videoData,
  ] );

  // Redirect to first available section when user clicks on Media nav link a second time.
  useEffect( () => {
    if (
      location.pathname === '/media'
      && photoGalleryData
      && virtualTourData
      && videoData
    ) {
      const gotPhotos = photoGalleryData?.length > 0;
      const gotTours = virtualTourData?.length > 0;
      const gotVideos = videoData?.length > 0;

      // Show content for the first available section.
      switch ( true ) {
        case gotPhotos:
          history.push( `/media/galleries/${search}` );
          break;
        case gotTours:
          history.push( `/media/tours/${search}` );
          break;
        case gotVideos:
          history.push( `/media/videos/${search}` );
          break;
        default:
          break;
      }
    }
  }, [
    location.pathname,
    photoGalleryData,
    virtualTourData,
    videoData,
  ] );

  return (
    <div className="content media">

      {!photoGalleryData && !virtualTourData && !videoData && <LoadingSpinner />}

      {navMarkup}

      <Route
        render={( { location } ) => (
          <FadingContainer>
            <div className="gridContainer">
              <Switch location={location}>
                <Route
                  path="/media/galleries"
                  render={() => <MediaPagePhotoGalleryList items={photoGalleryData} />}
                />
                <Route
                  path="/media/videos"
                  render={() => <MediaPageVideoList items={videoData} />}
                />
                <Route
                  path="/media/tours"
                  render={() => <MediaPageVirtualTourList items={virtualTourData} />}
                />
              </Switch>
            </div>
          </FadingContainer>
        )}
      />
    </div>
  );
};

export default MediaPage;
