import React from 'react';
import propTypes from 'prop-types';

import AmenitiesPageListItem from './AmenitiesPageListItem';

const AmenitiesPageList = ( { items } ) => (
  <div className="gridContainer">
    { items?.map( ( item ) => (
      <AmenitiesPageListItem
        key={item.uuid}
        item={item}
      />
    ) )}
  </div>
);

AmenitiesPageList.propTypes = {
  items: propTypes.arrayOf(
    propTypes.shape( {
      uuid: propTypes.string,
      title: propTypes.string,
      coverImage: propTypes.string,
    } ),
  ),
};

AmenitiesPageList.defaultProps = {
  items: [],
};

export default AmenitiesPageList;
