import React, {
  useContext,
  useEffect,
  useState,
} from 'react';
import propTypes from 'prop-types';

import ConfigContext from '../../utils/ConfigContext/ConfigContext';
import { getNormalizedApiDataByUuid } from '../../utils/ApiHelpers';
import { residenceNormalizedData } from './ResidenceNormalizedData';
import { assembleResidenceNavBtnsData } from './ResidenceHelpers';

import FadingContainer from '../FadingContainer/FadingContainer';

import DetailTable from '../Detail/DetailTable';
import FloorPlanDetailCore from '../FloorPlan/FloorPlanDetailCore';

const ResidenceDetail = ( {
  uuid,
  setModalNavBtns,
} ) => {
  const [ unitData, setUnitData ] = useState( null );
  const [ showOccupants, setShowOccupants ] = useState( false );
  const [ showOccupancy, setShowOccupancy ] = useState( false );
  const [ residenceInfo, setResidenceInfo ] = useState( [] );

  const {
    config,
    featureFlags,
    isKiosk,
  } = useContext( ConfigContext );
  const { isPIB, isSiteSee } = config;

  // Get unit data.
  useEffect( () => {
    const fetchData = async () => {
      const include = [
        'field_floor_plan',
        'field_floor_plan.field_2d_floor_plan',
        'field_floor_plan.field_3d_floor_plan',
        'field_floor_plan.field_finish_collection',
        'field_floor_plan.field_finish_collection.field_finish_option',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_space',
        'field_floor_plan.field_finish_collection.field_finish_option.field_finish_palette',
        'field_floor_plan.field_finish_collection.field_finish_option.field_media_image',
        'field_floor_plan.field_photo_gallery.field_gallery_photos',
        'field_floor_plan.field_video',
        'field_floor_plan.field_virtual_tour',
        'field_floor_plan.field_virtual_tour.field_three_sixties',
        'field_building_grouping',
        'field_level',
        'field_status',
        'field_residents',
        'field_2d_floor_plan',
        'field_3d_floor_plan',
        'field_finish_collection',
        'field_finish_collection.field_finish_option',
        'field_finish_collection.field_finish_option.field_finish_space',
        'field_finish_collection.field_finish_option.field_finish_palette',
        'field_virtual_tour.field_three_sixties',
        'field_video',
        'field_view_video',
        'field_photo_gallery.field_gallery_photos',
        'field_gallery_photos',
        'field_view_gallery.field_gallery_photos',
      ];
      const request = {
        entityType: 'node',
        contentType: 'residence',
        uuid,
        params: {
          include: include.join( ',' ),
        },
      };
      const data = await getNormalizedApiDataByUuid( request, residenceNormalizedData );
      setUnitData( data );
    };
    if ( uuid ) {
      fetchData();
    }
  }, [ uuid ] );

  // Should we show occupant names?
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_occupants' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_occupants' );
    } else if ( isSiteSee ) {
      show = featureFlags?.includes( 'sitesee_show_occupants' );
    } else {
      show = featureFlags?.includes( 'yt_show_occupants' );
    }
    setShowOccupants( show );
  }, [
    isKiosk, isPIB, isSiteSee, featureFlags,
  ] );

  // Should we show occupancy status?
  useEffect( () => {
    let show = false;
    if ( isKiosk ) {
      show = featureFlags?.includes( 'kiosk_show_occupancy' );
    } else if ( isPIB ) {
      show = featureFlags?.includes( 'pib_show_occupancy' );
    } else if ( isSiteSee ) {
      show = featureFlags?.includes( 'sitesee_show_occupancy' );
    } else {
      show = featureFlags?.includes( 'yt_show_occupancy' );
    }
    setShowOccupancy( show );
  }, [
    isKiosk, isPIB, isSiteSee, featureFlags,
  ] );

  // Set modal nav buttons.
  useEffect( () => {
    if ( unitData ) {
      const rawNavBtns = assembleResidenceNavBtnsData( unitData );
      setModalNavBtns( rawNavBtns );
    }
  }, [ unitData ] );

  // Create location and status phrase data.
  const location = unitData?.location;
  const status = unitData?.status;
  const residents = unitData?.residents;
  useEffect( () => {
    const tableInfo = [];
    if ( location && location !== '' ) {
      tableInfo.push( {
        label: 'Location',
        data: location,
      } );
    }
    let statusPhrase = showOccupancy ? status : null;
    if ( residents && residents !== '' && status !== 'Available' && status !== undefined && showOccupants ) {
      switch ( status ) {
        case 'Sold':
          statusPhrase = `Sold to ${residents}`;
          break;
        case 'Reserved':
          statusPhrase = `Reserved by ${residents}`;
          break;
        case 'Occupied':
          statusPhrase = `Occupied by ${residents}`;
          break;
        default:
          statusPhrase = `${status} for ${residents}`;
          break;
      }
    }
    if ( statusPhrase ) {
      tableInfo.push( {
        label: 'Status',
        data: statusPhrase,
      } );
    }
    setResidenceInfo( tableInfo );
  }, [
    location, residents, status, showOccupants,
  ] );

  return unitData && (
    <FadingContainer>
      <div>

        <DetailTable
          id="info-scroll"
          tableInfo={residenceInfo}
          tableDescription={unitData.description}
          className="detailSection"
        />

        <DetailTable
          tableInfo={unitData.roomFees}
          className="detailSection detailSection__displayFees"
        />

        <FloorPlanDetailCore
          title={unitData.title}
          roomFees={unitData.roomFees}
          floorplan2d={unitData.twoDFloorPlan?.modal}
          floorplan3d={unitData.threeDFloorPlan?.modal}
          downloadablePdf={unitData.downloadablePdf}
          description={unitData.description}
          roomData={unitData.roomsDims}
          view={unitData.view}
          photoGalleryImages={unitData.photoGallery?.images}
          video={unitData.video}
          threeSixties={unitData.virtualTour?.threeSixties}
          furnitureConfiguratorUrl={unitData.furnitureConfiguratorUrl}
          finishCollection={unitData.finishCollection}
        />

      </div>
    </FadingContainer>
  );
};

ResidenceDetail.propTypes = {
  uuid: propTypes.string,
  setModalNavBtns: propTypes.func,
};

ResidenceDetail.defaultProps = {
  uuid: null,
  setModalNavBtns: null,
};

export default ResidenceDetail;
